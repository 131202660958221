<template>
  <div class="open-meeting">
    <h1 class="header-title">Что такое открытое собрание?</h1>
    <p class="text-block">
      Открытое собрание групп АН – это собрание, которое могут посещать люди, не
      страдающие от химической зависимости. На открытые собрания могут приходить
      супруги и члены семей зависимых, друзья, родственники, а также
      профессионалы разных областей, интересующиеся программой Анонимных
      Наркоманов.<br />
      Открытые собрания по формату не отличаются от обычных, за исключением
      того, что независимые гости не высказываются, а только слушают. Если у
      гостей возникают какие-либо вопросы по поводу увиденного и услышанного на
      собраниях, они могут задать их после окончания собрания ведущему или
      любому другому члену АН.
    </p>
    <h2 class="header-title_sub">
      Вы родственник, супруг или друг зависимого?
    </h2>
    <p class="text-block">
      Вы можете найти ближайшее открытое собрание в нашем
      <router-link class="router-link" to="/timetable">расписании</router-link>
      и прийти туда вместе с зависимым (при его/её желании) или самостоятельно.
      Наше сообщество анонимно, поэтому никакой регистрации не требуется. На
      собрании вы можете представиться вымышленным именем и обозначить, что вы
      являетесь независимым гостем. Собрания полностью бесплатны и анонимны.
    </p>
    <h2 class="header-title_sub">
      Вы не уверены, зависимый ли вы, и хотите просто посмотреть?
    </h2>
    <p class="text-block">
      Если вы сомневаетесь в том, есть ли у вас проблемы с наркотиками или
      алкоголем, вы также можете прийти на наши открытые собрания. Послушав опыт
      членов АН, вы можете принять решение о том, зависимый ли вы, и стоит ли
      вам посещать наши собрания. На собрании вам не обязательно обозначать, что
      у вас есть какие-либо проблемы с наркотиками или алкоголем, достаточно
      просто представиться гостем.
    </p>
    <h2 class="header-title_sub">
      Вы профессионал? Психолог, врач, журналист, сотрудник полиции?
    </h2>
    <p class="text-block">
      Вы также можете посетить наши открытые собрания, но пожалуйста, согласуйте
      свой визит, обратившись на нашу телефонную линию. Мы заботимся об
      анонимности членов нашего сообщества, и поэтому должны сообщать им о
      присутствии профессионалов на собраниях. Некоторые из наших членов в
      прошлом имели негативный опыт общения с сотрудниками полиции, врачами,
      психологами и психиатрами, и поэтому предпочитают не посещать собрания, на
      которых присутствуют профессионалы.
    </p>
    <ul class="text-block">
      <li>
        Поскольку наше сообщество анонимно,
        <b
          >мы не собираем и не предоставляем никаких сведений о наших членах.</b
        >
      </li>
      <li>
        Согласно нашим Традициям, <b>мы не занимаемся рекламой</b>. Если вы
        представляете какой-либо реабилитационный центр или иное коммерческое
        учреждение – пожалуйста, воздержитесь от рекламы ваших услуг, как на
        собраниях так и после них.
      </li>
      <li>
        Согласно нашим Традициям,
        <b>мы не принимаем посторонней помощи</b> (финансовой или иного рода),
        даже в безвозмездной форме.
      </li>
      <li>
        Мы поддерживаем безопасную атмосферу выздоровления на наших собраниях и
        поэтому просим вас не приносить на собрания наркотики, алкоголь, или
        какие-либо атрибуты употребления этих веществ.
      </li>
      <li>Мы просим вас соблюдать наши Традиции и правила групп.</li>
    </ul>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "Что такое открытое собрание?";
  },
};
</script>